const Cookies = require("js-cookie");
var hostname = window.location.href;

module.exports = {
  server: hostname.includes("localhost")
    ? "http://localhost:7400"
    : "https://api.cheapship.info",

  config: {
    headers: {
      Authorization: "Bearer " + Cookies.get("footprint"),
    },
  },

  checkAccess: (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        Cookies.remove("footprint");
        window.location.href = "/";
      }
    }
    return true;
  },
};
